import webAndServicesWatermarkIcon from '../../icons/our-service/website&services.png';
import digitalAgencyWatermarkIcon from '../../icons/our-service/digital-agency.png';
import softwareDevWatermarkIcon from '../../icons/our-service/software-development.png';
import itconsultantWatermarkIcon from '../../icons/our-service/it-consultant.png';
import otherServicesWatermarkIcon from '../../icons/our-service/other-service.png';

export const OurServiceData = [
    {
        title: 'OUR SERVICE',
        cName: 'h-our-services__inner__item--first',
        content: 'There are many variations of passages of Lorem Ipsum available, Contrary to popular belief, Lorem Ipsum is not simply random',
        clickable: false
    },
    {
        title: 'Website & Services',
        cName: 'h-our-services__inner__item',
        watermarkIcon: webAndServicesWatermarkIcon,
        content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. Nunc sed arcu vel turpis accumsan cursus',
        clickable: true
    },
    {
        title: 'Digital Agency',
        watermarkIcon: digitalAgencyWatermarkIcon,
        cName: 'h-our-services__inner__item',
        content: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined, fermentum sed ante a, imperdiet porttitor arcu.',
        clickable: true
    },
    {
        title: 'Software Development',
        watermarkIcon: softwareDevWatermarkIcon,
        cName: 'h-our-services__inner__item',
        content: 'Nulla lobortis varius finibus. Nunc sed arcu vel turpis accumsan cursus. Morbi orci orci, fermentum sed ante a, imperdiet porttitor arcu.',
        clickable: true
    },
    {
        title: 'IT Consultant',
        watermarkIcon: itconsultantWatermarkIcon,
        cName: 'h-our-services__inner__item',
        content: 'Morbi orci orci, fermentum sed ante a, imperdiet porttitor arcu. Nunc sed arcu vel turpis accumsan cursus. Lorem Ipsum is not simply random text.',
        clickable: true
    },
    {
        title: 'Other Service',
        watermarkIcon: otherServicesWatermarkIcon,
        cName: 'h-our-services__inner__item',
        content: 'Suspendisse potenti. Duis a dui vel sem rutrum ultricies vitae ac sapien. Lorem Ipsum is not simply random text.',
        clickable: true
    }
];