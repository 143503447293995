import React from 'react';

import './OurServicesItem.styles.css';



const OurServicesItem = ({ title, content, cName, active, click, watermarkIcon }) => {
    const watermarkStyle = {
        backgroundImage: `url(${watermarkIcon})`,
        backgroundRepeat:'no-repeat',
        backgroundSize: '30% 90%',
        backgroundPosition:'right 10px center'
    };

    return (
        <div className={`${cName} ${active}`} onClick={click} style={watermarkStyle}>
            <h4><b>{title}</b></h4>
            {content}
        </div>
    );
};

export default OurServicesItem;