import React from 'react';
import { Container } from 'react-bootstrap';

import './HeroSection.styles.css';

const HeroSection = () => {
    return (
        <Container fluid className="hero-image">
            <div className="hero-caption-container">
                <h5 className="mb-4">Software Development and IT Consultant</h5>
                <h3 className="mb-4"><span className="des">Maewnam Network Solutions</span></h3>
                <a className="btn btn-contact-us" href="javascript:void(0)">Contact Us</a>
            </div>
        </Container>
    );
};

export default HeroSection;