import webAndServicesIcon from '../../icons/services-bar/Website-icon.png';
import digitalAgencyIcon from '../../icons/services-bar/digital-agency.png';
import softwareDevIcon from '../../icons/services-bar/software-development.png';
import consultantIcon from '../../icons/services-bar/it-consultant.png';
import otherServicesIcon from '../../icons/services-bar/other-service.png';

export const ServicesItem = [
    { title: 'Website & Services', icon: webAndServicesIcon, active: 'active', activeBorder: 'active-border' },
    { title: 'Digital Agency', icon: digitalAgencyIcon, active: 'active', activeBorder: 'active-border' },
    { title: 'Software Development', icon: softwareDevIcon, active: 'active', activeBorder: 'active-border' },
    { title: 'IT Consultant', icon: consultantIcon, active: 'active', activeBorder: 'active-border' },
    { title: 'Other Services', icon: otherServicesIcon, active: 'active', activeBorder: 'active-border' }
];