import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './HomePage.styles.css';

import dummyBlogSection from '../../images/home-blogs.png';
import dummyMapSection from '../../images/map-section.png';
import dummyFooterSection from '../../images/footer-section.png';

import macImage from '../../images/macbook.png';
import HeroSection from '../../components/HeroSection/HeroSection.component';
import ServicesCard from '../../components/ServicesCard/ServicesCard.component';
import AboutUsBar from '../../components/AboutUsBar/AboutUsBar.component';
import OurServices from '../../components/OurServices/OurServices.component';

import websitePortfolioBg from '../../images/portfolio-section/website.png';
import softwarePortfolioBg from '../../images/portfolio-section/software.png';
import marketingPortfolioBg from '../../images/portfolio-section/marketing.png';
import otherPortfolioBg from '../../images/portfolio-section/other.png';

const HomePage = () => {
    return (
        <div>
            <HeroSection />
            <Container className="services-card-container">
                <ServicesCard />
            </Container>
            <div className="h-about-us-container">
                <h3 className="mt-5"><b>About Us</b></h3>
                <AboutUsBar />
                <div className="my-5">
                    <div className="h-about-us-content">
                        <div>
                            <img className="h-about-us-content__image" src={macImage} width="100%" height="auto" />
                        </div>
                        <div className="h-about-us-content__text">
                            <p> - บริการออกแบบเว็บไซต์ให้มีคุณภาพและตอบโจย์ความต้องการของลูกค้า
                            มีทางเลือกต่าง ๆ มากมายที่ใช้งานทำให้ลูกค้าสามารถปรับเปลี่ยนข้อมูลของคุณมีประสิทธิภาพและง่ายมากขึ้น
                            เรามีทีมงานมืออาชีพที่จะช่วยแก้ปัญหาต่าง ๆ ทำงานไปร่วมกันกับลูกค้า และนำเสนอเครื่องมือที่มาพร้อมกับเว็บไซต์
                                    ที่ใช้ง่านง่ายและตรงจุด</p>
                            <p> - พัฒนาระบบอย่างมีประสิทธิภาพ ด้วยทีมงานที่มีคุณภาพ ออกแบบระบบอย่างเป็นขั้นตอนเชื่อถือได้
                            เพื่อให้ได้ระบบที่ปลอดภัยและมีความยืดหยุ่นสูง ตามความต้องการของลูกค้า
                            และธุรกิจของลูกค้า เรายังมีการพัฒนาระบบแบบ AGILE ซึ่งจะทำให้ลูกค้ามีส่วนร่วมในการพัฒนาโปรแกรม
                                    เพื่อให้ได้งานที่ถูกต้องชัดเจนยิ่งขึ้น</p>
                            <p>- บริการทำการตลาดของเว็บไซต์ หรือการนำเสนอเนื้อหาต่างๆ
                            เพื่อให้ลูกค้าสามารถนำเสนอสินค้าและแบรนด์สู่ตลาดของคุณได้มากขึ้น ผ่านช่องทางต่าง ๆ
                                    ทีมีประสิทธิภาพมากที่สุด โดยผู้เชียวชาญที่ทำงานมามากว่า 10 ปี</p>
                        </div>
                    </div>
                </div>
            </div>
            <OurServices />
            <div className="portfolio-section">
                <div className="portfolio-section__item">
                    <div className="portfolio-section__item__image" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${websitePortfolioBg}) ` }}></div>
                    <div className="portfolio-section__item__button"><a href="#">Website</a></div>
                </div>
                <div className="portfolio-section__item">
                    <div className="portfolio-section__item__image" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${softwarePortfolioBg})` }}></div>
                    <div className="portfolio-section__item__button"><a href="#">Software</a></div>
                </div>
                <div className="portfolio-section__item">
                    <div className="portfolio-section__item__image" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${marketingPortfolioBg})` }}></div>
                    <div className="portfolio-section__item__button"><a href="#">Marketing Online</a></div>
                </div>
                <div className="portfolio-section__item">
                    <div className="portfolio-section__item__image" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${otherPortfolioBg})` }}></div>
                    <div className="portfolio-section__item__button"><a href="#">Other</a></div>
                </div>
            </div>
            <div><img src={dummyBlogSection} height="auto" width="100%" /></div>
            <div><img src={dummyMapSection} height="auto" width="100%" /></div>
            <div><img src={dummyFooterSection} height="auto" width="100%" /></div>
        </div>
    );
};

export default HomePage;