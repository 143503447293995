import React from 'react';
import { Image } from 'react-bootstrap';

import './ServicesCardItem.styles.css';

const ServicesCardItem = ({ title, icon, active, activeBorder, click, mouseOver,mouseLeave }) => {
    return (
        <div className={`services-card__item ${active} ${activeBorder}`} onClick={click} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
            <div className="mb-3"><Image src={icon} className="services-card__item__image" alt={`icon-${title}`} /></div>
            <span>{title}</span>
        </div>
    );
}

export default ServicesCardItem;