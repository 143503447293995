import React, { Component } from 'react';
import { ServicesItem } from './ServicesCard.data';
import './ServicesCard.styles.css';

import ServicesCardItem from '../ServicesCardItem/ServicesCardItem.component';

class ServicesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: {
                currentIndex: null,
                prevIndex: null
            },
            mouseOverItem: {
                currentIndex: null,
                prevIndex: null
            },
            mouseLeaveItem: {
                currentIndex: null,
                prevIndex: null
            }
        };
    }

    handleClick = idx => {
        this.setState({ activeItem: { currentIndex: idx, prevIndex: idx - 1 } });
    };

    handleMouseOver = idx => {
        this.setState({ mouseOverItem: { currentIndex: idx, prevIndex: idx - 1 } });
    };

    handleMouseLeave = idx => {
        this.setState({ mouseLeaveItem: { currentIndex: idx, prevIndex: idx - 1 } });
    };
    

    render() {
        return (
            <div className="services-card-inner">
                {ServicesItem.map(({ title, icon, active, activeBorder }, idx) => (
                    <ServicesCardItem
                        key={`${title}_${idx}`}
                        title={title}
                        icon={icon}
                        active={(this.state.activeItem.currentIndex === idx) ? active : ''}
                        activeBorder={
                            (this.state.activeItem.currentIndex === idx || this.state.activeItem.prevIndex === idx)  ? '' : activeBorder
                        }
                        click={e => this.handleClick(idx)}
                        // mouseOver={e => this.handleMouseOver(idx)}
                        // mouseLeave={e => this.handleMouseLeave(idx)}
                    />
                ))}
            </div>
        );
    }
}

export default ServicesCard;