import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomNavbar from "./components/Navbar/Navbar.component";

import './App.css';

import HomePage from './pages/HomePage/HomePage.component';

function App() {
  return (
    <div className="App">
      <CustomNavbar />
      <Switch>
        <Route exact path="/" component={HomePage} />
      </Switch>
    </div>
  );
}

export default App;
