import React, { Component } from 'react';
import mainLogo from '../../images/logo/logo_maewnam_menubar.png';
import phoneIcon from '../../icons/phone.png';
import { MenuItems } from './MenuItems.data';
// import { Button } from '../Button/Button.component';
import { Container, Navbar, Nav, Form, Image } from 'react-bootstrap';

import './Navbar.styles.css';

class CustomNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false };
    }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" sticky="top" className="navbar-container">
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            src={mainLogo}
                            width="auto"
                            height="60"
                            className="d-inline-block align-top"
                            alt="Maewnam logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto nav-link-container">
                            {MenuItems.map((menu, idx) => <Nav.Link className="custom-nav-link-item" href={menu.url} key={`${menu.title}_${idx}`}>{menu.title}</Nav.Link>)}
                        </Nav>
                        <Form inline>
                            <Image src={phoneIcon} width="auto" height="auto" alt="phone-icon" />&nbsp;<span className="nav-phone">086 - 9847722</span>
                        </Form>
                    </Navbar.Collapse>
                </Container >
            </Navbar>
        );
    }
}

export default CustomNavbar;