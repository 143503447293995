import React, { Component } from 'react';

import { OurServiceData } from './OurServices.data';
import OurServicesItem from '../OurServicesItem/OurServicesItem.component';

import './OurServices.styles.css';

class OurServices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 1
        };
    }

    handleClick = (index, clickable) => {
        if (clickable) {
            return this.setState({ activeIndex: index });
        }
        return;
    };

    render() {
        return (
            <div className="h-our-services">
                <div className="h-our-services__inner">
                    {OurServiceData.map(({ title, content, cName, watermarkIcon, clickable }, idx) => (
                        <OurServicesItem
                            key={`${title}_${idx}`}
                            title={title}
                            content={content}
                            cName={cName}
                            watermarkIcon={watermarkIcon}
                            active={(this.state.activeIndex === idx) ? 'active' : ''}
                            click={e => this.handleClick(idx, clickable)}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default OurServices;