import React, { Component } from 'react';

import './AboutUsBar.styles.css';

class AboutUsBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSide: 'left'
        };
    }

    handleClick = side => {
        this.setState({ activeSide: side });
    };

    render() {
        return (
            <ul className="about-us__line">
                <li className={`mb-4 ${this.state.activeSide === 'left' ? 'active' : ''}`} onClick={e => this.handleClick('left')}><span>What are we doing?</span></li>
                <li className={`mb-4 ${this.state.activeSide === 'right' ? 'active' : ''}`} onClick={e => this.handleClick('right')}><span>Our story</span></li>
            </ul >
        );
    }
}

export default AboutUsBar;